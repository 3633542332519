const getCookie = (name, cookie) => {
	const value = `; ${cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return decodeURIComponent(parts.pop().split(';').shift())
	}
}

const getDatepickerTranslations = locale => {
	if (locale === 'ru') {
		return {
			formatLocale: {
				months: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
				monthsShort: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
				weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
				weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
				weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
			},
		}
	}

	return {
		formatLocale: {
			months: ['yan', 'fev', 'mar', 'apr', 'may', 'iyun', 'iyul', 'avg', 'sen', 'okt', 'noy', 'dek'],
			monthsShort: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul', 'Avgust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr'],
			weekdays: ['Yakshanba', 'Dushanba', 'Seshanba', 'Chorshanba', 'Payshanba', 'Juma', 'Shanba'],
			weekdaysShort: ['ya', 'du', 'se', 'ch', 'pa', 'ju', 'sh'],
			weekdaysMin: ['ya', 'du', 'se', 'ch', 'pa', 'ju', 'sh'],
		}
	}
}

const generateOfferFeatures = offer => {
	const features = {}
	const baggage = []
	const handbags = []
	const change = []
	const refund = []

	offer.directions.forEach(direction => {
		baggage.push(direction.segments.map(segment => segment.baggage?.piece === null ? null : !!segment.baggage?.piece))
		handbags.push(direction.segments.map(segment => !!segment.handbags?.piece))
		change.push(direction.segments.map(segment => segment.change))
		refund.push(direction.segments.map(segment => segment.refund))
	})

	if (baggage.flat().every(truthy => truthy)) {
		features.baggage = true
	} else if (baggage.flat().some(piece => piece === null)) {
		features.baggage = null
	} else if (baggage.flat().every(truthy => !truthy) || offer.directions.length === 1) {
		features.baggage = false
	}

	if (handbags.flat().every(truthy => truthy)) {
		features.handbags = true
	} else if (handbags.flat().every(truthy => !truthy) || offer.directions.length === 1) {
		features.handbags = false
	}

	if (change.flat().every(truthy => truthy)) {
		features.change = true
	} else if (change.flat().every(truthy => !truthy) || offer.directions.length === 1) {
		features.change = false
	}

	if (refund.flat().every(truthy => truthy)) {
		features.refund = true
	} else if (refund.flat().every(truthy => !truthy) || offer.directions.length === 1) {
		features.refund = false
	}

	return features
}

export function checkPinfl(pinfl) {
	if (/^\d{14}$/.test(pinfl)) {
		const gen = Number(pinfl[0]);
		let century = '19';

		if (gen <= 2) {
			century = '18';
		} else if (gen >= 5) {
			century = '20';
		}

		const birthYear = century + pinfl.substring(5, 7);
		const birthMonth = pinfl.substring(3, 5);
		const birthDay = pinfl.substring(1, 3);
		const birthDate = birthMonth + '/' + birthDay + '/' + birthYear;

		if (
			new Date(birthDate).toLocaleDateString('en', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
			}) === birthDate &&
			gen > 0 &&
			gen < 7
		) {
			let total = 0;

			for (let i = 0; i <= pinfl.length - 2; i++) {
				const remainder = i % 3;
				let weightDigit = 0;

				if (remainder === 0) {
					weightDigit = 7;
				} else if (remainder === 1) {
					weightDigit = 3;
				} else {
					weightDigit = 1;
				}

				total = total + Number(pinfl[i]) * weightDigit;
			}

			if (total % 10 === Number(pinfl[pinfl.length - 1])) {
				return true;
			}
		} else {
			return false;
		}
	}

	return false;
}

export function getBirthdateFromPinfl(pinfl) {
	const gen = Number(pinfl[0]);
	let century = '19';

	if (gen < 3) {
		century = '18';
	} else if (gen > 4) {
		century = '20';
	}

	const birthYear = century + pinfl.substring(5, 7);
	const birthMonth = pinfl.substring(3, 5);
	const birthDay = pinfl.substring(1, 3);

	return `${birthYear}-${birthMonth}-${birthDay}`;
}

export {
	getCookie,
	getDatepickerTranslations,
	generateOfferFeatures,
}
